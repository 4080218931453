import React from "react"
import Layout from "../components/layout"

function Personvernerklering() {
  return (
    <Layout>
    <div className="my-12">
    <h1 className="text-xl font-bold">Personvernerklæring</h1>
      <p>Vi behandlar personopplysningar om deg i følgjande behandling:</p>
      <h2 className="mt-3 text-lg font-bold">Føremål</h2>
      <p>
        Vi handsamer personopplysningar om deg for følgjande føremål:
        <ul className="ml-12">
          <li className="list-disc">
            Epost og namn for å administerere og sende ut nyheitsbrev.
          </li>
          <li className="list-disc">
            Epost, namn og andre opplysningar du sender for å administerere og levere det du bestiller hjå oss.
          </li>
          <li className="list-disc">
            IP adresse og anna anonym statistisk infomasjon for å kunne operere nettsida vår. 
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">Behandlingsgrunnlag</h2>
      <p>
        Rettsgrunnlaget for behandlinga vår av personopplysningane dine følgjer
        av:
        <ul className="ml-12">
          <li className="list-disc">GDPR artikkel 6 nr. 1 a) samtykke</li>
          <li className="list-disc">
            GDPR artikkel 6 nr. 1 e) berettiga interesse
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">Kategoriar av registererte</h2>
      <p>
        Følgjane kategoriar av registererte vert behandla:
        <ul className="ml-12">
          <li className="ml-3 ">
            Kunder og andre om er interersert i kva vi tilbyr
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">Kategoriar av personopplysningar</h2>
      <p>
        Vi behandlar følgjande kategoriar av personopplysningar om deg:
        <ul className="ml-12">
          <li className="list-disc">Namn</li>
          <li className="list-disc">E-postdresse</li>
          <li className="list-disc">Bestillingar</li>
          <li className="list-disc">Anna opplysningar du gjev oss</li>
          <li className="list-disc">
            IP-adresse og anna i loggen når du besøkar nettsida vår
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">Mottakarar</h2>
      <p>INGEN. Vi delar ikkje personopplysningane med nokon andre.</p>
      <h2 className="mt-3 text-lg font-bold">Kvar samler vi opplysningane frå:</h2>
      <p>
        Vi samlar inn opplysningar frå desse kjeldene:
        <ul className="ml-12">
          <li className="list-disc">
            Du gjev frå deg opplysningane når du fyllar ut skjema
          </li>
          <li className="list-disc">
            Du legg igjen opplysningar når du besøker nettsida vår.
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">
        Overføring av personopplysningane til tredjeland
      </h2>
      <p>
        <ul className="ml-12">
          <li className="list-disc">
            Det vert ikkje overført personopplysningar utanfor EU/EØS
          </li>
        </ul>
      </p>
      <h2 className="mt-3 text-lg font-bold">Lagring av personopplysningane</h2>
      <p>
        Vi lagrar personopplysningane din slik:
        <ul className="ml-12">
          <li className="list-disc">
            Opplysningar som du gjev frå deg når du bestillar aktivitetar vert
            sletta når føremålet er oppfylt
          </li>
          <li className="list-disc">
            Personopplysnignar som du gjev får deg når du melder deg på
            nyheitsbrevet vert sletta når du melder deg av nyhetsbrevet.
          </li>
          <li className="list-disc">
            Personpplysningar som vert henta inn når du besøker nettsida er
            anonymiserte slik at vi ikkje kan kjenne deg igjen.
          </li>
        </ul>
      </p>
      <p className="mt-4">
          Om det er noko du lurar på i høve behandlinga vår av personopplysningar kan du kontakte oss på <a className="text-green-800 hover:text-green-600" href="mailto:post@jolsteradventure.no">post@joslsteradventure.no</a>
      </p>
    </div>
     
    </Layout>
  )
}

export default Personvernerklering
